/*
  Helpers
*/

.earth-map-bg{
  background-image: url('../images/dotted-map.png');
  background-size: contain;
}

.geometric-bg{
  background-image: url('../images/geometric-pattern.png');
  background-repeat: repeat;
}

.gradient{
  background: linear-gradient(90deg, #cf93ff 0%, @primary 100%);
}

.gradient-text:not([data-gradients]){
  .gradient();
}

.gradient-text{
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.upper{
  text-transform: uppercase;
}

.fw-300{
  font-weight: 300;
}

.fw-400{
  font-weight: 400;
}

.fw-500{
  font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

.full-height{
  height: auto;
  min-height: 100vh;
}

.height-100{
  height: 100vh;
}

.colored-bg{
  background-color: @primary;
  color: #fff;
}

.d-b{
  display: block;
}

.font-variant-small-caps{
  font-variant: small-caps;
  text-transform: none!important;
}

.centrize{
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  position: relative;
}

.v-center{
  display: table-cell;
  vertical-align: middle;
}

.v-top{
  display: table-cell;
  vertical-align: top;
}

.v-bottom{
  display: table-cell;
  vertical-align: bottom;
}

.ov-v{
  overflow: visible;
}

.ov-h{
  overflow: hidden;
}

.b-0{
  border: 0;
}

.bt-0{
  border-top: 0;
}

.br-0{
  border-right: 0;
}

.bb-0{
  border-bottom: 0;
}

.bl-0{
  border-left: 0;
}

.border-top{
  border-top: 1px solid #eee;
}

.border-right{
  border-right: 1px solid #eee;
}

.border-bottom{
  border-bottom: 1px solid #eee;
}

.border-left{
  border-left: 1px solid #eee;
}

.m-0{
  margin: 0;
}

.mt{
  &-0{
    margin-top: 0;
  }
  &-10{
    margin-top: 10px;
  }
  &-15{
    margin-top: 15px;
  }
  &-25{
    margin-top: 25px;
  }
  &-35{
    margin-top: 35px;
  }
  &-50{
    margin-top: 50px;
  }
  &-100{
    margin-top: 100px;
  }
}

.mr{
  &-0{
    margin-right: 0;
  }
  &-10{
    margin-right: 10px;
  }
  &-15{
    margin-right: 15px;
  }
  &-25{
    margin-right: 25px;
  }
  &-35{
    margin-right: 35px;
  }
  &-50{
    margin-right: 50px;
  }
  &-100{
    margin-right: 100px;
  }
}

.mb{
  &-0{
    margin-bottom: 0;
  }
  &-10{
    margin-bottom: 10px;
  }
  &-15{
    margin-bottom: 15px;
  }
  &-25{
    margin-bottom: 25px;
  }
  &-35{
    margin-bottom: 35px;
  }
  &-50{
    margin-bottom: 50px;
  }
  &-100{
    margin-bottom: 100px;
  }
}

.ml{
  &-0{
    margin-left: 0;
  }
  &-10{
    margin-left: 10px;
  }
  &-15{
    margin-left: 15px;
  }
  &-25{
    margin-left: 25px;
  }
  &-35{
    margin-left: 35px;
  }
  &-50{
    margin-left: 50px;
  }
  &-100{
    margin-left: 100px;
  }
}

.p-0{
  padding: 0;
}

.pt{
  &-0{
    padding-top: 0;
  }
  &-10{
    padding-top: 10px;
  }
  &-15{
    padding-top: 15px;
  }
  &-25{
    padding-top: 25px;
  }
  &-35{
    padding-top: 35px;
  }
  &-50{
    padding-top: 50px;
  }
  &-100{
    padding-top: 100px;
  }
}

.pr{
  &-0{
    padding-right: 0;
  }
  &-10{
    padding-right: 10px;
  }
  &-15{
    padding-right: 15px;
  }
  &-25{
    padding-right: 25px;
  }
  &-35{
    padding-right: 35px;
  }
  &-50{
    padding-right: 50px;
  }
  &-100{
    padding-right: 100px;
  }
}

.pb{
  &-0{
    padding-bottom: 0;
  }
  &-10{
    padding-bottom: 10px;
  }
  &-15{
    padding-bottom: 15px;
  }
  &-25{
    padding-bottom: 25px;
  }
  &-35{
    padding-bottom: 35px;
  }
  &-50{
    padding-bottom: 50px;
  }
  &-100{
    padding-bottom: 100px;
  }
}

.pl{
  &-0{
    padding-left: 0;
  }
  &-10{
    padding-left: 10px;
  }
  &-15{
    padding-left: 15px;
  }
  &-25{
    padding-left: 25px;
  }
  &-35{
    padding-left: 35px;
  }
  &-50{
    padding-left: 50px;
  }
  &-100{
    padding-left: 100px;
  }
}


.font-16{
  font-size: 16px;
}

.font-18{
  font-size: 18px;
}

.font-20{
  font-size: 20px!important;
}

.font-26{
  font-size: 26px!important;
}

.font-32{
  font-size: 32px!important;
}

.black-text{
  color: @black!important;
}

.white-text{
  color: #fff!important;
}

.colored-text{
  color: @primary!important;
}

.gold-text{
  color: lighten(#b48b3c, 5%) !important;
}

.default-font{
  font-family: @primary_font, sans-serif!important;
}

.heading-font{
  font-family: @heading_font, sans-serif!important;
  letter-spacing: 0.02em;
}

.serif-font{
  font-family: @serif_font, serif!important;
  font-weight: 400!important;
}

.cursive-font{
  font-family: @cursive_font, cursive;
  font-weight: 400!important;
}

.alt-serif-font{
  font-family: @alt_serif_font, serif!important;
}

.txt{
  &-md{
    .bp-desktop({
      &-left{
        text-align: left;
      }
      &-center{
        text-align: center;;
      }
      &-right{
        text-align: right;
      }
    });
  }
  &-sm{
    .bp-tablet({
      &-left{
        text-align: left;
      }
      &-center{
        text-align: center;;
      }
      &-right{
        text-align: right;
      }
    });
  }
  &-xs{
    .bp-mobile({
      &-left{
        text-align: left;
      }
      &-center{
        text-align: center;;
      }
      &-right{
        text-align: right;
      }
    });
  }
}

.flex-order{
  &-md{
    .bp-desktop({
      &-2{
        order: 2;
      }
    });
  }
  &-sm{
    .bp-tablet-only({
      &-2{
        order: 2;
      }
    });
  }
  &-xs{
    .bp-mobile({
      &-2{
        order: 2;
      }
    });
  }
}

.box-shadow-big{
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
}

.pull{
  &-md{
    .bp-desktop({
      &-left{
        float: left;
      }
      &-right{
        float: right;
      }
    });
  }
  &-sm{
    .bp-tablet({
      &-left{
        float: left;
      }
      &-right{
        float: right;
      }
    });
  }
  &-xs{
    .bp-mobile({
      &-left{
        float: left;
      }
      &-right{
        float: right;
      }
    });
  }
}

.preview-icon{
  padding: 15px 10px;
  border: 1px solid #e7e7e7;
  margin-left: -15px;
  margin-right: -16px;
  margin-bottom: -1px;
  i{
    font-size: 25px;
    color: #484e54;
    width: 50px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
  }
  code{
    font-size: 12px;
    background-color: transparent;
    color: #191b1d;
  }
  &:hover{
    background-color: #f7f7f7;
    code, i {
      color: @primary;
    }
  }
}
