section.grey-bg{
  .timeline .timeline-content:before{
    border-color: #f7f7f7;
  }
}

section.dark-bg{
  .timeline{
    &:before{
      background-color: lighten(@black_bg, 10%);
    }
    .timeline-content:before{
      border-color: @black_bg;
      background-color: lighten(@black_bg, 10%);
      box-shadow: 0 0 0 1px lighten(@black_bg, 10%);
    }
  }
}

.timeline{
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  &:before{
    content: "";
    position: absolute;
    width: 1px;
    left: 10%;
    top: 0;
    bottom: 0;
    background-color: #e6e6e6;
  }
  &.boxed-timeline:before{
    top: 0;
    bottom: 0;
  }
  >li{
    margin-bottom: 35px;
    margin-left: 10%;
    &:last-child{
      margin-bottom: 0;
    }
    &:hover{
      .timeline-content{
        &:before{
          background-color: @primary;
          box-shadow: 0 0 0 1px @primary;
        }
        .timeline-date{
          margin-left: -16px;
        }
      }
    }
    .timeline-content{
      padding: 0 50px;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 14px;
        height: 14px;
        background-color: #e6e6e6;
        transition: all .3s ease;
        z-index: 100;
        margin-left: -7px;
        border-radius: 50%;
      }
      h4{
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 14px;
      }
      h5{
        display: block;
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 11px;
        text-transform: uppercase;
        color: @text;
        letter-spacing: 0.05em;
        font-weight: 500;
      }
      p{
        margin-top: 10px;
        margin-bottom: 0;
      }
      .timeline-date{
        position: relative;
        display: inline-block;
        padding: 0 12px;
        font-size: 11px;
        font-weight: 500;
        font-family: @heading_font, sans-serif;
        letter-spacing: 0.05em;
        margin-bottom: 15px;
        text-transform: uppercase;
        color: #fff;
        background-color: @primary;
        height: 22px;
        line-height: 22px;
        margin-left: -12px;
        transition: all .3s ease;
        &:before{
          content: "";
          position: absolute;
          display: block;
          bottom: 0;
          border: 11px solid @primary;
          z-index: -1;
          left: -12px;
          border-right-width: 11px;
          border-left-color: transparent !important;
        }
      }
    }
  }
}
