*{
  min-height: 0;
  min-width: 0;
}

body{
  font-family: @primary_font, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: @text;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  &.boxed-layout{
    background-color: #f2f2f2;
  }
}

.bp-desktop({
  body.left-navbar{
    #wrapper{
      padding-left: 240px;
      overflow-x: hidden;
    }
    #footer{
      left: 240px;
    }
  }
});

::selection{
  background-color: @black_bg;
  color: #fff;
}

img, video{
  max-width: 100%;
  height: auto;
}

a{
  color: @black;
  &:hover{
    color: lighten(@black, 30%);
    text-decoration: none;
  }
  &:focus{
    color: lighten(@black, 30%);
    text-decoration: none;
  }
  &.small-link{
    font-family: @heading_font, sans-serif;
    font-size: 11px;
    letter-spacing: 0.05em;
    font-weight: 500;
    >span{
      display: inline-block;
      vertical-align: middle;
    }
    >i{
      display: inline-block;
      position: relative;
      vertical-align: middle;
      font-size: 10px;
      width: 10px;
      height: 8px;
      margin-left: 3px;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
    }
    &.black-text:hover{
      color: @primary!important;
    }
    &:hover{
      >i{
        margin-left: 7px;
      }
    }
  }
  &.white-text{
    &:hover{
      color: @primary;
    }
  }
  &.arrow-link{
    font-family: @heading_font, sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    position: relative;
    display: inline-block;
    &:after{
      content: "\e019";
      font-family: 'hody-icons';
      font-size: 18px;
      vertical-align: middle;
      transition: all .3s ease;
      margin-left: 2px;
    }
    &:hover:after{
      margin-left: 8px;
    }
  }
}

dd {
  margin-bottom: 20px;
}

ul {
  list-style: disc;
}

li > ul,
li > ol {
  padding: 0;
  margin-left: 15px;
}

select{
  max-width: 100%;
}

.table {
	font-size: 14px;
  >thead>tr>th {
    color: @black;
  	border-bottom: 1px solid #e7e7e7;
  	font-family: @heading_font, sans-serif;
  	font-size: 11px;
  	font-weight: 500;
  	letter-spacing: 0.05em;
  }
}

iframe{
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6{
  font-family: @heading_font, sans-serif;
  color: @black;
  line-height: 1.3;
}

h1, h2, h3, h4{
  font-weight: 700;
}

h5, h6{
  font-weight: 600;
}

blockquote{
  border-left: 0;
  padding: 0;
  h1, h2, h3, h4, h5, h6{
    line-height: inherit;
  }
  footer{
    color: inherit;
    font-family: @heading_font, sans-serif;
  }
}

hr{
  border-color: #e6e6e6;
}

#wrapper{
  position: relative;
  z-index: 20;
  overflow-x: hidden;
  section.last-section{
    box-shadow: 0 20px 20px 0px rgba(0,0,0,0.02);
  }
  .boxed-layout &{
    .bp-large-screen({
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;
      box-shadow: none;
      .container {
        width: 1140px;
      }
    });
  }
}

#loader{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: @black;
  z-index: 100000;
}

#mask{
  width: 200px;
  height: 200px;
  line-height: 200px;
  margin: 0 auto;
  text-align: center;
  svg{
    width: 64px;
    height: 76px;
  }
}

.preloader-path {
  stroke-linejoin: round;
  fill: none;
  stroke: #fff;
  stroke-width: 1;
  stroke-dasharray: 70 32;
  animation: preloader 1s infinite ease;
}

section{
  position: relative;
  background-color: #fff;
  padding-top: 110px;
  padding-bottom: 110px;
  z-index: 10;
  &+section{
    border-top: 1px solid #eee;
  }
  &.home-section + section{
    border-top: 0;
  }
  &.grey-bg, &.dark-bg{
    border-top: 0;
    &+section{
      border-top: 0;
    }
  }
  .bp-desktop({
    &.split-section+.split-section{
      border-top: 0;
    }
  });
  &.parallax-section{
    background-color: transparent;
    border-top: 0;
    color: #fff;
    &.simple-parallax{
      height: 400px;
    }
    .parallax-bg{
      .gradient-bg-wrapper{
        z-index: 0;
        opacity: .9;
      }
    }
    .parallax-overlay{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: @black;
      opacity: .7;
      &.gradient-overlay{
        &:not([data-gradients]){
          .gradient();
        }
        background-color: transparent;
        opacity: .9;
      }
      &.semilight-overlay{
        opacity: .5;
      }
      &.colored-overlay{
        background-color: @primary;
      }
    }
    &.text-dark{
      .parallax-overlay{
        opacity: .1;
      }
    }
    .title{
      >i{
        color: #fff;
      }
    }
    &+section{
      border: 0;
    }
  }
  &.big-padding-bt{
    padding-bottom: 220px!important;
  }
  &.section-bordered{
    border: 20px solid #fff;
    &.home-section{
      border: 0;
      #home-slider{
        border: 20px solid #fff;
        border-bottom: 0;
        padding-bottom: 20px;
        &:after{
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          height: 20px;
          background-color: #fff;
        }
      }
    }
  }
}

section.page-title{
  height: 450px;
  &.parallax-section{
    height: 550px;
  }
  .bp-desktop({
    h1{
      font-size: 58px;
    }
  })
  .title{
    margin-bottom: 0;
  }
}

.grey-bg{
  background-color: @grey_bg;
}

.dark-bg{
  background-color: @black_bg;
  color: #fff;
  .title{
    >hr{
      border-color: #fff;
    }
  }
}

.title{
  margin-bottom: 25px;
  position: relative;
  z-index: 10;
  >h1{
    font-size: 48px;
    letter-spacing: -1px;
    &.upper{
      letter-spacing: 0.03em;
    }
    &.font-big{
      font-size: 66px;
    }
  }
  >h2{
    font-size: 38px;
    letter-spacing: -1px;
    &.alt-serif-font{
      font-size: 36px;
    }
    >.fw-300{
      letter-spacing: 0;
    }
    &.upper{
      letter-spacing: 0.03em;
    }
    &.font-big{
      font-size: 56px;
    }
    &.font-xl{
      font-size: 96px;
    }
    .bp-tablet({
      font-size: 32px;
      &.font-big{
        font-size: 40px;
      }
      &.font-xl{
        font-size: 58px;
      }
    });
  }
  >h3{
    letter-spacing: -1px;
    &.cursive-font{
      letter-spacing: 0;
      font-size: 19px;
      &+h2{
        margin-top: 0;
      }
    }
    &.alt-serif-font{
      font-size: 22px;
    }
  }
  >h4{
    font-weight: 400;
    font-size: 18px;
    font-family: @primary_font, sans-serif;
    color: @text;
    &.upper{
      font-size: 14px;
      letter-spacing: 0.1em;
    }
    &.font-xl{
      font-size: 66px;
    }
  }
  >h6{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.05em;
    margin-top: 0;
  }
  >i{
    font-size: 45px;
    color: @black;
  }
  .title-icon{
    position: relative;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    text-align: center;
    line-height: 65px;
    transition: all .3s ease;
    display: inline-block;
    background-color: @primary;
    clear: both;
    color: #fff;
    >i{
      font-size: 32px;
    }
  }
  >p{
    font-size: 24px;
    margin: 20px 0;
    .bp-tablet({
      font-size: 20px;
    });
  }
  >hr{
    width: 40px;
    margin: 20px 0 15px;
    border-color: @black_bg;
    transition: width .3s ease;
    &.fullwidth{
      width: 100%;
      border-color: #e6e6e6;
    }
    &.colored{
      border-color: @primary;
    }
    &.white{
      border-color: #fff;
    }
    &.right-align{
      margin-left: auto;
      margin-right: 0;
    }
  }
  &:hover{
    >hr:not(.fullwidth){
      width: 60px;
    }
  }
  &.text-center{
    hr{
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.title{
  &.text-center{
    h2[data-bigletter]:before{
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &.txt-sm-center{
    .bp-tablet({
      h2[data-bigletter]:before{
        left: 0;
        right: 0;
        margin: auto;
      }
    });
  }
  h2[data-bigletter]{
    position: relative;
    &:before{
      z-index: -1;
      content: attr(data-bigletter);
      font-size: 2em;
      opacity: .06;
      position: absolute;
      top: -.5em;
      left: -.15em;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.row-parallax-bg{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  .parallax-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130%;
    height: calc(~"100% + 30vh");
    .parallax-bg{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.video-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  .video-player{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video{
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: none;
      z-index: -1;
    }
  }
  .fallback-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .bp-desktop({
      display: none;
    });
  }
}

.vertical-divider{
  height: 70px;
  width: 1px;
  background-color: @black_bg;
  margin: 50px auto 0;
}

.img-cover{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  .bp-tablet-only({
    min-height: 350px;
  });
  .bp-mobile({
    min-height: 300px;
  });
}

.img-side {
  padding: 0;
  position: absolute!important;
  top: 0px;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  &.fixed-image{
    position: fixed!important;
  }
  &.img-right{
    right: 0;
  }
  &.img-left{
    left: 0;
  }
  &.img-center{
    left: 0;
    right: 0;
    margin: auto;
  }
  .bp-mobile({
    min-height: 400px;
    margin: -110px 0 60px;
    position: relative!important;
    z-index: 0;
    .img-holder{
      .title{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    section.p-0 &, section.pt-0 & {
      margin-top: 0;
    }
  });
}

.img-holder {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  &.overlay{
    color: #fff;
    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(33, 40, 45, 0.6);
    }
    .title{
      p{
        color: #fff;
      }
    }
  }
  .title{
    padding-left: 100px;
    padding-right: 100px;
    .bp-tablet-only({
      display: none;
    });
  }
  &.with-border{
    border: 20px solid #fff;
  }
}

.holder-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: fade(@black, 50%);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }
}

.fixed-bg-section{
  section{
    padding-left: 5%;
    padding-right: 5%;
  }
  .img-side{
    .bp-tablet({
      min-height: 500px;
      position: relative!important;
      z-index: 0;
      .img-holder{
        .title{
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    });
  }
}

.typed-words{
  &:after{
    content: "|";
    animation: blink 0.7s infinite;
    font-weight: lighter;
    color: @text;
  }
}

.typed-cursor {
  display: none;
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.dark-bg, .parallax-section:not(.text-dark), .gradient-bg, .colored-bg{
  h1, h2, h3, h4, h5, h6, a:not(.btn){
    color: inherit;
  }
  .counter-content{
    border-color: lighten(@black_bg, 8%);
  }
  #filters{
    >li{
      color: #fff;
      &.active{
        border-color: #fff;
        color: #fff;
        &:hover{
          color: #fff;
        }
      }
    }
  }
  .icon-box>i, .social-list > li > a{
    color: #fff;
  }
  .minimal-tabs > li{
    >a{
      color: #fff;
      &:hover{
        color: #fff;
      }
    }
    &.active{
      >a, >a:focus, >a:hover{
        color: #fff;
        border-color: #fff;
      }
    }
  }
  .accordion > li.active .accordion-title{
    background-color: #fff;
    border-color: #fff;
    &:before{
      color: @black;
      border-color: @black;
      border-width: 1px;
    }
    h4{
      color: @black;
    }
  }
}

.go-top{
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 50px;
  right: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #dedede;
  background-color: transparent;
  z-index: 100;
  border-radius: 50%;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(.3);
  transform: scale(.3);
  &.showed-up{
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  &:hover{
    background-color: @primary;
  }
  a{
    color: lighten(@black, 45%);
    display: block;
    i{
      font-size: 26px;
      line-height: inherit;
      &:before{
        line-height: inherit;
      }
    }
  }
}

.row-flex{
  display: flex;
  flex-wrap: wrap;
  &:before{
    content: none;
  }
  >[class*="col-"]{
    display: flex;
    align-items: stretch;
    .bp-mobile({
      &:not([class*="col-xs"]){
        width: 100%;
      }
    });
    .column-inner{
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      margin-left: -15px;
      margin-right: -15px;
      &.with-border{
        margin-right: -14px;
        margin-top: 1px;
        margin-bottom: 1px;
      }
    }
  }
  &.flex-center{
    >[class*="col-"]{
      align-items: center;
    }
  }
  &.full-width-flex{
    .bp-tablet-md({
      >[class*="col-"]{
        padding-left: 6.5%;
        padding-right: 6.5%;
      }
    });
  }
  &.padding-row{
    >[class*="col-"]{
      padding: 7.5% 6.5%;
    }
  }
  &.flex-horizontal{
    >[class*="col-"]:last-child{
      justify-content: flex-end;
    }
  }
  &.flex-spacing{
    .column-inner{
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.column-inner{
  &.with-padding{
    padding: 65px 50px;
  }
  &.with-border{
    box-shadow: 0 0 0 1px #eee;
  }
}

.boxed-tabs, .minimal-tabs{
  &.center-tabs{
    text-align: center;
    font-size: 0;
    >li{
      display: inline-block;
      float: none;
    }
  }
}

.boxed-tabs{
  border-bottom: 0;
  margin-bottom: 25px;
  &.cols-6>li{
    width: 16.66666667%;
  }
  &.cols-5>li{
    width: 20%;
  }
  &.cols-4>li{
    width: 25%;
  }
  &.cols-3>li{
    width: 33.33333333%;
  }
  &.cols-2>li{
    width: 50%;
  }
  .bp-tablet({
    &.cols-6>li, &.cols-5>li, &.cols-4>li, &.cols-3>li{
      width: 50%;
      padding: 4px;
      border: 0;
      float: left;
      display: block;
    }
  });
  >li{
    border-right: 1px solid fade(#000, 7%);
    &:last-child{
      border-right: 0;
    }
    >a{
      text-transform: uppercase;
      padding: 15px 20px;
      letter-spacing: 0.15em;
      font-size: 11px;
      border-radius: 0;
      color: @black;
      font-family: @heading_font, sans-serif;
      font-weight: 500;
      background-color: #f4f4f4;
      border: 0;
      margin-right: 0;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      &:hover{
        background-color: #eee;
        border-color: transparent;
      }
    }
    &.active{
      border-color: @primary;
      >a, >a:focus, >a:hover{
        border: 0;
        background-color: @primary;
        color: #fff;
        cursor: pointer;
        outline: none;
      }
    }
  }
  &.red-skin>li.active{
    border-color: #f42841;
    >a, >a:focus, >a:hover{
      background-color: #f42841;
    }
  }
}

.minimal-tabs{
  margin-bottom: 25px;
  border-bottom: 0;
  >li{
    >a{
      text-transform: uppercase;
      margin-right: 20px;
      letter-spacing: 0.1em;
      font-size: 11px;
      border-radius: 0;
      font-weight: 500;
      color: lighten(@black, 15%);
      font-family: @heading_font, sans-serif;
      border: 0;
      border-bottom: 1px solid transparent;
      transition: all .3s ease;
      &:hover, &:focus{
        background-color: transparent;
        border-color: transparent;
        color: @black;
      }
      .bp-mobile({
        margin-bottom: 10px;
        margin-right: 0;
      });
    }
    &.active{
      >a, >a:focus, >a:hover{
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid @black_bg;
        color: @black;
        outline: none;
      }
    }
  }
}

.icon-tabs{
  border-bottom: 0;
  margin-bottom: 25px;
  text-align: center;
  font-size: 0;
  &.cols-6>li{
    width: 16.66666667%;
  }
  &.cols-5>li{
    width: 20%;
  }
  &.cols-4>li{
    width: 25%;
  }
  &.cols-3>li{
    width: 33.33333333%;
  }
  &.cols-2>li{
    width: 50%;
  }
  .bp-tablet({
    &.cols-6>li, &.cols-5>li, &.cols-4>li, &.cols-3>li{
      width: 50%;
    }
  });
  >li{
    width: 25%;
    display: inline-block;
    float: none;
    >a{
      text-transform: uppercase;
      margin-right: 20px;
      letter-spacing: 0.1em;
      font-size: 12px;
      border-radius: 0;
      font-weight: 500;
      color: lighten(@black, 15%);
      font-family: @heading_font, sans-serif;
      border: 0;
      margin-right: 0;
      transition: all .3s ease;
      &:hover, &:focus{
        background-color: transparent;
        border-color: transparent;
      }
      >i{
        display: block;
        font-size: 42px;
        margin-bottom: 20px;
      }
    }
    &.active{
      >a, >a:focus, >a:hover{
        opacity: 1;
        border: 0;
        background-color: transparent;
        color: @primary;
        cursor: pointer;
        outline: none;
      }
    }
  }
}

.tab-content{
  p{
    line-height: 28px;
  }
}

.play-button{
  cursor: pointer;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid @black;
  border-radius: 50%;
  line-height: 76px;
  color: @black;
  text-align: center;
  font-size: 36px;
  position: relative;
  z-index: 10;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  a{
    color: inherit;
  }
  i{
    line-height: inherit;
    &:before{
      line-height: inherit;
    }
  }
  &:hover{
    background-color: @black;
    color: #fff;
    transform: scale(.9);
  }
}

.btn[data-play-button]{
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  &:hover{
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.testimonials-slider{
  .testimonial{
    padding-left: 25px;
    padding-right: 25px;
    .bp-mobile({
      padding-left: 15px;
      padding-right: 15px;
    });
  }
  .testimonial-content{
    background-color: #fff;
    padding: 75px 100px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    max-width: 750px;
    margin: 50px auto;
    text-align: center;
    border-radius: 10px;
    .bp-mobile({
      padding: 100px 50px 50px;
    });
    >img{
      width: 100px;
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-top: -125px;
    }
  }
  .testimonial-comment{
    >h4{
      margin-top: 25px;
      margin-bottom: 10px;
      font-size: 24px;
      letter-spacing: -0.03em;
    }
    >p{
      margin: 25px 0;
      font-size: 18px;
      font-weight: 300;
    }
    .testimonial-info{
      text-transform: uppercase;
      font-family: @heading_font, serif;
      font-size: 12px;
      letter-spacing: 0.03em;
      font-weight: 500;
    }
  }
  .bp-tablet({
    .slick-arrow{
      display: none;
      visibility: hidden;
    }
  });
}

section.parallax-section{
  .testimonials-slider{
    .testimonial-content{
      background-color: transparent;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 50px;
      box-shadow: none;
      .bp-mobile({
        padding: 0;
      });
      >img{
        margin-top: 0;
      }
    }
  }
  .slick-dots > li:not(.slick-active) > button{
    background-color: fade(#fff, 30%);
  }
}

.slick-slider:not(#home-slider):not(#product-slider-nav){
  .slick-prev, .slick-next{
    position: absolute;
    top: 50%;
    border: 0;
    background-color: transparent;
    height: 50px;
    margin-top: -25px;
    font-size: 52px;
    line-height: 50px;
    outline: none;
    transition: all .3s ease;
    z-index: 100;
    &:hover{
      color: @primary;
    }
    i{
      line-height: inherit;
    }
  }
  .slick-prev{
    left: 25px;
    &:hover{
      left: 20px;
    }
  }
  .slick-next{
    right: 25px;
    &:hover{
      right: 20px;
    }
  }
}

.slick-slide{
  outline: none;
}

.slick-dots{
  margin: auto;
  text-align: center;
  padding-left: 0;
  margin: 0 0 15px;
  list-style-type: none;
  >li{
    display: inline-block;
    margin: 0 4px;
    >button{
      font-size: 0;
      border: 0;
      padding: 0;
      width: 11px;
      height: 11px;
      outline: none;
      background-color: rgba(0,0,0,.1);
      border-radius: 50%;
    }
    &.slick-active{
      >button{
        background-color: @primary;
      }
    }
  }
}

.dark-dots{
  .slick-dots > li.slick-active > button{
    background-color: @black_bg;
  }
}

.white-dots{
  .slick-dots > li.slick-active > button{
    background-color: #fff;
  }
}

.dots-abs{
  .slick-dots{
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 100%;
  }
}

.accordion{
  padding-left: 0;
  list-style: none;
  margin: 0;
  >li{
    margin-bottom: 20px;
    &.active{
      .accordion-title{
        color: @primary;
        border-color: @primary;
        h4{
          color: @primary;
        }
        &:before{
          content: "\e0e9";
          color: @primary;
          border-color: @primary;
        }
      }
    }
  }
  &.dark-skin>li{
    &.active{
      .accordion-title{
        color: @black;
        border-color: @black;
        h4{
          color: @black;
        }
        &:before{
          color: @black;
          border-color: @black;
        }
      }
    }
  }
  &.dark-blue-skin>li{
    &.active{
      .accordion-title{
        color: #007aff;
        border-color: #007aff;
        h4{
          color: #007aff;
        }
        &:before{
          color: #007aff;
          border-color: #007aff;
        }
      }
    }
  }
}

.accordion-title{
  margin: 0;
  padding: 20px 20px;
  padding-left: 70px;
  position: relative;
  cursor: pointer;
  border: 2px solid #e6e6e6;
  transition: all .3s ease;
  &:before{
    content: "\e000";
    position: absolute;
    left: 0;
    top: -2px;
    bottom: -2px;
    width: 57px;
    text-align: center;
    line-height: 65px;
    font-family: "hody-icons";
    font-size: 24px;
    border-right: 2px solid #e6e6e6;
    transition: all .3s ease;
  }
  h4{
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    transition: all .3s ease;
  }
}

.accordion-content{
  display: none;
  padding: 20px 0;
  p{
    margin: 0;
  }
}

.check-list{
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  >li{
    padding: 6px 0;
    &:before{
      font-family: 'hody-icons';
      content: "\e045";
      vertical-align: middle;
      font-size: 18px;
      margin-right: 10px;
      color: @primary;
    }
  }
  &.dark-skin{
    >li:before{
      color: @black;
    }
  }
  &.white-skin{
    >li:before{
      color: #fff;
    }
  }
}

.parallax-section{
  .check-list>li:before{
    color: #fff;
  }
}

.tagcloud, .post-tags{
  a{
    display: block;
    float: left;
    padding: 8px 10px;
    border: 1px solid lighten(@text, 5%);
    margin: 0 10px 10px 0;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-family: @heading_font, sans-serif;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    font-weight: 600;
    color: @text;
    &:hover{
      background-color: @primary;
      border-color: @primary;
      color: #fff;
    }
  }
}

section.dark-bg, section.colored-bg{
  .progress{
    background-color: fade(#fff, 15%);
  }
  .progress-bar:not(.colored){
    background-color: #fff;
  }
}

.progress{
  height: 2px;
  background-color: #eee;
  margin-bottom: 25px;
  box-shadow: none;
}

.progress-bar{
  background-color: @black_bg;
  box-shadow: none;
  &.colored{
    background-color: @primary;
  }
}

.skill{
  position: relative;
  text-align: left;
}

.skill-name{
  position: relative;
}

.skill-name, .skill-perc {
  margin-bottom: 15px;
  display: block;
  font-size: 13px;
  font-weight: 500;
}

.skill-perc {
  position: absolute;
  top: 0;
}

.carousel{
  max-width: 100%;
  .slick-list.draggable{
    cursor: grab;
    &:active{
      cursor: grabbing;
    }
  }
  .carousel-item{
    &.with-padding{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

#map{
  &.full-width{
    height: 500px;
    width: 100%;
    position: relative!important;
  }
}

.map-side{
  .bp-tablet({
    padding: 0;
    margin-top: -120px;
    margin-bottom: 50px;
    #map{
      min-height: 400px;
      width: 100%;
      position: relative!important;
      margin-bottom: 35px;
    }
  });
  .bp-desktop({
    padding: 0px;
    position: absolute;
    top: 0px;
    height: 100%;
    overflow: hidden;
    z-index: 100;
    left: 0;
    &.map-right{
      right: 0;
      left: auto;
    }
    #map {
      min-height: 500px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
    }
  });
}

.map-box{
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  #map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
}

.menu-list{
  padding-left: 0;
  list-style: none;
  margin: 0;
  text-align: left;
  &.serif-font{
    h4{
      font-family: @serif_font, sans-serif;
      font-weight: 400;
      text-transform: none;
      font-size: 15px;
      letter-spacing: 0;
    }
  }
  >li{
    padding: 15px 0;
    overflow: hidden;
    border-bottom: 1px solid #eee;
    margin: 10px 0;
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      border-bottom: 0;
    }
    h4{
      margin: 0;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.05em;
      position: relative;
      font-weight: 600;
      &:after{
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 2px;
        z-index: -1;
      }
    }
    .menu-value{
      float: right;
      padding-left: 5px;
      margin-top: 1px;
      background-color: #fff;
    }
    .menu-text{
      font-size: 14px;
      padding: 15px 0 0;
      margin-bottom: 0;
    }
    .menu-title{
      background-color: #fff;
      padding-right: 5px;
    }
    .menu-content{
      padding-right: 25px;
    }
  }
}

section.grey-bg{
  .menu-list{
    .menu-title, .menu-value{
      background-color: @grey_bg;
    }
  }
}

section.dark-bg{
  .menu-list{
    .menu-title, .menu-value{
      background-color: @black_bg;
    }
  }
}

.ig{
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.12);
}

.box-with-image{
  position: relative;
  .box-content{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: fade(#000, 30%);
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    h3{
      color: #fff;
      font-size: 32px;
      letter-spacing: -1px;
      &.cursive-font{
        font-size: 38px;
        letter-spacing: 0;
      }
    }
  }
}

section.section-skewed{
  padding-bottom: 160px;
  &.home-section{
    padding-bottom: 0;
  }
  .skewed-mask {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 6%;
    z-index: 1;
    pointer-events: none;
    .mask-block {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      top: 0;
      left: 0;
      backface-visibility: hidden;
      transform: skewY(-3deg);
      transform-origin: 100% 0;
    }
  }
  &.skewed-reverse{
    .skewed-mask .mask-block{
      transform: skewY(3deg);
      transform-origin: 0 100%;
    }
  }
  &+section{
    border-top: 0;
  }
}

section.gradient-bg{
  &:not([data-gradients]){
    .gradient();
  }
  color: #fff;
  border: 0;
}

.gradient-bg-wrapper, .gradient-bg-element{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.client-carousel{
  figure{
    line-height: 100px;
    margin: 0 auto;
    max-width: 150px;
    padding: 0 10px;
    display: block;
    img{
      display: inline-block;
      vertical-align: middle;
      opacity: .8;
      transition: opacity .3s ease;
      &:hover{
        opacity: 1;
      }
    }
  }
}

.client-image{
  max-width: 160px;
  display: block;
  margin: 0 auto;
  line-height: 70px;
  img{
    display: inline-block;
    vertical-align: middle;
    opacity: .8;
    transition: opacity .3s ease;
  }
  &:hover{
    img{
      opacity: 1;
    }
  }
}

.clients-box{
  [class*="col-"]{
    padding: 5%;
  }
}

#particle-canvas{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
}

.inline-list{
  list-style: none;
  padding-left: 0;
  >li{
    padding: 12px 0;
    border-bottom: 1px solid #e6e6e6;
    &:last-child{
      border-bottom: 0;
    }
    .list-title{
      display: inline-block;
      min-width: 120px;
      font-weight: 500;
      color: @black;
    }
  }
}

.social-list{
  padding: 0;
  list-style: none;
  margin: 0;
  &.size--big{
    >li{
      >a{
        width: 75px;
        font-size: 34px;
      }
    }
  }
  &.text-center{
    >li{
      margin: 5px;
    }
  }
  >li{
    display: inline-block;
    margin-right: 10px;
    >a{
      display: block;
      font-size: 16px;
      width: 40px;
      color: lighten(@text, 10%);
      transition: all .3s ease;
      .dark-bg &, .colored-bg &{
        color: #fff;
      }
      &:hover{
        color: @primary;
      }
      i{
        line-height: inherit;
      }
    }
    &.social-item-facebook>a:hover{
      color: #3b5998;
    }
    &.social-item-twitter>a:hover{
      color: #55acee;
    }
    &.social-item-linkedin>a:hover{
      color: #0077b5;
    }
    &.social-item-instagram>a:hover{
      color: #3f729b;
    }
    &.social-item-dribbble>a:hover{
      color: #ea4c89;
    }
    &.social-item-github>a:hover{
      color: #333;
    }
    &.social-item-flickr>a:hover{
      color: #0063dc;
    }
    &.social-item-pinterest>a:hover{
      color: #bd081c;
    }
    &.social-item-youtube>a:hover{
      color: #cd201f;
    }
    &.social-item-tumblr>a:hover{
      color: #35465c;
    }
    &.social-item-snapchat>a:hover{
      color: #fffc00;
    }
  }
}

#search-modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: fade(#000, 90%);
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
  &.active{
    opacity: 1;
    visibility: visible;
    .search-form{
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
  .search-form{
    max-width: 700px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    visibility: hidden;
    transform: scale(.8);
    transition: all .3s ease;
    transition-delay: .3s;
    ::placeholder {
      color: #fff;
    }
    ::selection{
      background-color: @primary;
      color: #fff;
    }
    .form-control{
      padding: 0 25px;
      border-color: #999;
      color: #fff;
      background-color: transparent;
      border-width: 1px;
      text-transform: none;
      font-size: 18px;
      letter-spacing: 0;
      height: 60px;
      line-height: 60px;
      &:focus{
        border-color: @primary;
        &+.input-group-btn{
          .btn{
            border-color: @primary;
          }
        }
      }
    }
    .btn{
      background-color: transparent;
      border-width: 1px 1px 1px 0;
      border-color: #999;
      height: 62px;
      font-size: 18px;
    }
  }
  #close-search-modal{
    position: absolute;
    top: 1em;
    right: 1em;
    color: #fff;
    font-size: 40px;
    transition: all .3s ease;
    &:hover{
      transform: scale(.8);
    }
  }
}

.image-box{
  position: relative;
  overflow: hidden;
  .bp-tablet({
    max-width: 500px;
    margin: 50px auto;
  });
  img{
    transition: all .5s ease;
  }
  &:hover{
    img{
      transform: scale(1.1) translateX(10px);
    }
  }
  .image-overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: fade(#000, 50%);
    color: #fff;
    padding: 35px;
    h1, h2, h3, h4, h5, h6{
      color: inherit;
    }
  }
}

.error-page{
  text-align: center;
  .title{
    >h1{
      font-size: 160px;
      line-height: 1;
      .bp-mobile({
        font-size: 100px;
      });
    }
  }
}

.job-offer{
  padding: 20px 0;
  border-bottom: 1px solid #e7e7e7;
  overflow: hidden;
}

.job-info{
  .clearfix();
  h3{
    margin-top: 0;
    letter-spacing: -1px;
  }
  h5{
    font-size: 11px;
    color: @text;
    letter-spacing: 0.05em;
    font-weight: 500;
  }
}

.job-content{
  margin-top: 20px;
  p{
    margin-bottom: 15px;
  }
  a{
    color: @primary;
  }
}

.images-gallery.slick-slider{
  &:hover{
    .slick-prev, .slick-next{
      opacity: 1;
    }
  }
  .slick-prev, .slick-next{
    opacity: 0;
    transition: all .3s ease;
    color: #fff;
    text-shadow: 0 1px 1px fade(#000, 30%);
    &:hover{
      color: @black!important;
      text-shadow: 0 1px 1px fade(#fff, 30%);
    }
  }
}

.media-video{
  width: 100%;
  padding-bottom: 60%;
  position: relative;
  iframe, embed{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
  }
}

.media-audio{
  width: 100%;
  padding-bottom: 20%;
  position: relative;
  iframe{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
  }
}

.alert{
  border-radius: 0;
  font-size: 16px;
  padding: 25px 25px 25px 100px;
  border: 0;
  color: #fff;
  position: relative;
  margin-bottom: 25px;
  p{
    margin: 0;
  }
  .alert-icon{
    background-color: fade(#000, 7.5%);
    position: absolute;
    width: 75px;
    text-align: center;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
      font-size: 20px;
    }
  }
  &.alert-dismissible{
    padding-right: 35px;
  }
  .close{
    position: absolute;
    right: 20px;
    top: 50%;
    height: 26px;
    line-height: 26px;
    margin-top: -13px;
    opacity: 1;
    outline: none;
    i{
      text-shadow: none;
      font-size: 22px;
      font-weight: bold;
    }
  }
  &.alert-warning{
    background-color: #ffbb44;
  }
  &.alert-danger{
    background-color: #f42841;
  }
  &.alert-success{
    background-color: #0ee092;
  }
  &.alert-info{
    background-color: #35c3fa;
  }
}
