.clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.bp-mobile(@rules) {
	@media only screen and (max-width: 767px) { @rules(); }
}

.bp-tablet(@rules) {
	@media only screen and (max-width: 991px) { @rules(); }
}

.bp-tablet-md(@rules) {
	@media only screen and (min-width: 768px) { @rules(); }
}

.bp-tablet-only(@rules) {
	@media only screen and (min-width: 768px) and (max-width: 991px) { @rules(); }
}

.bp-desktop(@rules) {
	@media only screen and (min-width: 992px) { @rules(); }
}

.bp-large-screen(@rules) {
	@media only screen and (min-width: 1200px) { @rules(); }
}
