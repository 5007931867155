.photo-gallery{
  width: 100%;
}

.gallery-item{
  .gallery-overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: fade(#000, 60%);
    transition: all .3s ease;
    opacity: 0;
    cursor: zoom-in;
  }
  &:hover{
    .gallery-overlay{
      opacity: 1;
    }
  }
}


#gallery-modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: fade(#000, 85%);
  z-index: 2000000;
  text-align: center;
  display: none;
  cursor: zoom-out;
  .video-gallery{
    max-width: 800px;
    margin: auto;
    position: relative;
  }
}

.lightbox-gallery{
  position: relative;
  transform: translateY(-200px);
  transition: all .7s ease;
  .single-gallery{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100vh;
    img, iframe{
      max-height: 85vh;
      max-width: 100%;
      margin: 0 auto;
      cursor: default;
    }
  }
}

#gallery-close{
  position: absolute;
  height: 40px;
  line-height: 40px;
  top: 20px;
  right: 30px;
  margin: auto;
  font-size: 35px;
  color: #fff;
  text-align: center;
  transition: all .3s ease;
  z-index: 1000;
  cursor: pointer;
  &:hover{
    transform: scale(1.1);
  }
  &:hover, &:focus{
    color: #fff;
  }
  i{
    vertical-align: middle;
    line-height: inherit;
  }
}

.gallery-control{
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  top: 50%;
  margin-top: -25px;
  color: #fff;
  font-size: 35px;
  transition: all .3s ease;
  &:hover, &:focus{
    color: #fff;
  }
  >a{
    color: #fff;
  }
  i{
    vertical-align: middle;
    line-height: inherit;
  }
  &.gallery-prev{
    left: 30px;
    text-align: left;
    &:hover{
      left: 25px;
    }
  }
  &.gallery-next{
    right: 30px;
    text-align: right;
    &:hover{
      right: 25px;
    }
  }
}

.lightbox-loader{
  background-image: url('../images/preloader.svg');
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 0;
  right: 0;
  margin: -25px auto 0;
}
