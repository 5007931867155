#footer{
  .bp-desktop({
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  });
  .boxed-layout &{
    .bp-large-screen({
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;
      box-shadow: none;
      .footer-widgets, .footer-copy{
        background-color: #fff;
      }
      .container {
        width: 1140px;
      }
    });
  }
}

.footer-widgets{
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: @grey_bg;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: url('/assets/img/dotted-map.png');
    background-size: contain;
    opacity: .8;
  }
  .widget{
    >h5{
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.05em;
      margin-bottom: 20px;
      font-weight: 600;
    }
    &+.widget{
      margin-top: 25px;
    }
    ul{
      padding-left: 0;
      list-style: none;
      >li{
        padding: 5px 0;
        >a{
          font-size: 15px;
          color: darken(@text, 5%);
          &:hover{
            color: @primary;
          }
        }
      }
    }
    .tagcloud a{
      border-color: lighten(@text, 30%);
      color: darken(@text, 10%);
      &:hover{
        background-color: @primary;
        border-color: @primary;
        color: #fff;
      }
    }
    .bp-tablet({
      margin-bottom: 50px;
      >h5{
        margin-bottom: 10px;
      }
    });
  }
}

.footer-logo{
  img{
    max-width: 140px;
  }
}

.footer-copy{
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  background-color: @grey_bg;
  border-top: 1px solid fade(@black_bg, 8%);
  .bp-tablet({
    .copy-text, .social-list{
      text-align: center;
    }
    .social-list{
      margin-bottom: 25px;
    }
  });
}

.copy-text{
  text-align: right;
  p{
    margin: 0;
  }
}

.bp-tablet({
  .footer-spacer{
    display: none;
  }
});

#footer.dark-footer{
  .footer-widgets{
    background-color: @black_bg;
    color: #fff;
    &:before{
      filter: invert(1);
      opacity: .6;
    }
    .widget{
      >h5{
        color: #fff;
      }
      ul{
        >li{
          >a{
            color: #bbb;
            &:hover{
              color: @primary;
            }
          }
        }
      }
      .tagcloud{
        a{
          color: #fff;
        }
      }
    }
  }
}
